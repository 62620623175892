import { css } from "@emotion/react";
import { graphql } from 'gatsby'
import React from "react";
import { Container, ContainerSmall, Div, Section } from "../components/Markup";
import { Title, Paragraph, Small } from "../components/Typography";
import COLORS from "../styles/colors"

const HeaderImage = ({ image }) => {

  return (
    <Div 
    mb="sm"
    css={css`
      @media screen and (orientation:landscape) {
        position: relative;
        overflow: hidden;
        height: 60vh;
      }
    `}
    >
      <img
      css={css`
      @media screen and (orientation:landscape) {
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate3d(-50%, -50%, 0);
      }
      
    `}
      alt=""
      src={ image.url }/>
      <Small mt="xs" mb="md">
        фотосинтеза: Иван Л.
      </Small>
    </Div>
  );
};




const AboutPage = (props) => {

  const { title, about, image } = props.data.queried_data

  return (
    <Section pt="lg" pb="md">
      <Container>
        <Div pt="md" pb="md">
          <HeaderImage image={image}/>
          <Title
            mb="sm"
            css={css`
              text-align: center;
            `}
          >
            { title }
          </Title>
          <span
            css={css`
              width: 1px;
              height: 90px;
              background: ${COLORS.black};
              display: block;
              margin: 0 auto;

              @media (min-width: 768px) {
                height: 150px;
              }
            `}
          />
        </Div>
        <ContainerSmall>
          <Paragraph pt="md" mb="md" dangerouslySetInnerHTML={{ __html: about }}/>
        </ContainerSmall>
      </Container>
    </Section>
  );
  
};

export default AboutPage;

export const query = graphql`
  query AboutQuery {
    queried_data: datoCmsAboutMe {
      id
      title
      about
      image {
        url
      }
    }
  }
`;